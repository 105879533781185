import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Environment
import { environment } from '../../../../environments/environment';

// Models
import { ClassificacaoEquipeModel } from '../../../views/pages/14-comercial/classificacao-equipe/models/classificacao-equipe.model';

// Providers
import { HttpService } from '../../../core/services/http.service';

// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
	providedIn: 'root'
})
export class EquipeVendaClassificacaoService implements IBaseService {

	readonly baseUrl: string = environment.comercialApiUrl + '/api/equipe-venda-classificacao/';

	constructor(private http: HttpService) {
	}

	listar(): string {

		return this.baseUrl + '0001';
	}

	cadastrar(model: ClassificacaoEquipeModel): Observable<any> {

		return this.http.post(this.baseUrl, model);
	}

	editar(model: ClassificacaoEquipeModel): Observable<any> {
		const query: any = {
			id: model.IDEquipeVendaClassificacao
		};
		return this.http.put(this.baseUrl, model, query);
	}

	excluir(model: ClassificacaoEquipeModel): Observable<any> {

		return this.http.delete(this.baseUrl + model.IDEquipeVendaClassificacao);
	}

	visualizar(id: number): Observable<any> {

		return this.http.get(this.baseUrl + id);
	}

	comboBox0002(filter: any): Observable<any> {

		const query: any = {
			...filter
		};

		return this.http.get(this.baseUrl + '0002', query);
	}

	comboBox0002Url(): string {

		return this.baseUrl + '0002';
	}

}
