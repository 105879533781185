import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { environment } from '../../../../environments/environment';

// Providers
import { HttpService } from '../../../core/services/http.service';

// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
	providedIn: 'root'
})
export class TCP0001Service implements IBaseService {

	readonly baseUrl: string = environment.engenhariaApiUrl + '/api/tcp0001/';
	readonly vixsysCadastroUrl: string = environment.vixSysCadastroApiUrl + '/api/tcp0001/';

	constructor(private http: HttpService) { }

	listar(): string {
		throw new Error('Method not implemented.');
	}

	cadastrar(model: object): Observable<any> {
		throw new Error('Method not implemented.');
	}

	editar(model: object): Observable<any> {
		throw new Error('Method not implemented.');
	}

	excluir(model: any): Observable<any> {
		throw new Error('Method not implemented.');
	}

	visualizar(id: number): Observable<any> {

		return this.http.get(this.baseUrl + id);
	}

	getCombo(filter: any): Observable<any> {

		return this.http.get(this.baseUrl + '0002', {
			...filter
		});
	}

	getComboUrl(): string {

		return this.baseUrl + '0002';
	}

	comboBox0001(): string {

		return this.vixsysCadastroUrl + '0001';
	}
}
