import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { environment } from '../../../../environments/environment';
import { EquipamentoModel } from '../../../views/pages/13-producao/equipamento/models/equipamento.model';

// Providers
import { HttpService } from '../../../core/services/http.service';
import { TokenStorage } from '../../../core/services/token-storage.service';

// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
	providedIn: 'root'
})
export class EquipamentoService implements IBaseService {

	readonly baseUrl: string = environment.cadastroApiUrl + '/api/equipamento/';

	constructor(private http: HttpService,
		private tokenStorage: TokenStorage) {
	}

	listar(): string {

		return this.baseUrl + '0005';
	}

	cadastrar(model: EquipamentoModel): Observable<any> {

		return this.http.post(this.baseUrl, model);
	}

	editar(model: EquipamentoModel): Observable<any> {

		const query: any = {
			id: model.IDEquipamento
		};

		return this.http.put(this.baseUrl, model, query);
	}

	excluir(model: EquipamentoModel): Observable<any> {

		return this.http.delete(this.baseUrl + model.IDEquipamento);
	}

	visualizar(id: number): Observable<any> {

		return this.http.get(this.baseUrl + id);
	}

	comboBox0002(filter: any): Observable<any> {

		const query: any = {
			'filtros.iDUnidadeNegocio': this.tokenStorage.getUnidadeUser().IDUnidadeNegocio,
			...filter
		};

		return this.http.get(this.baseUrl + '0002', query);
	}

	comboBox0002Url(): string {

		return this.baseUrl + '0002';
	}
}
