import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { environment } from '../../../../environments/environment';
import { SolicitacaoCompraModel } from '../../../views/pages/09-suprimentos/solicitacao-compra/models/solicitacao-compra.model';

// Providers
import { HttpService } from '../../../core/services/http.service';

// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
	providedIn: 'root'
})
export class SCO0003Service implements IBaseService {

	readonly baseUrl: string = environment.suprimentosApiUrl + '/api/sco0003/';

	constructor(private http: HttpService) { }

	listar(): string {
		return this.baseUrl + '0003';
	}

	cadastrar(model: SolicitacaoCompraModel): Observable<any> {

		return this.http.post(this.baseUrl, model);
	}

	editar(model: SolicitacaoCompraModel): Observable<any> {

		const query = {
			id: model.IDSCO0001
		};

		return this.http.put(this.baseUrl, model, query);
	}

	excluir(model: SolicitacaoCompraModel): Observable<any> {

		return this.http.delete(this.baseUrl + model.IDSCO0001);
	}

	visualizar(id: number): Observable<any> {

		return this.http.get(this.baseUrl + id);
	}

	listarByIDSCO0002(): string {

		return this.baseUrl + '0001';
	}

	liberarSolicitacao(id: number): Observable<any> {

		const query = {
			'request.iDSCO0002': id,
			'request.liberado': true
		};

		return this.http.post(this.baseUrl + 'liberar/0001', {}, query);
	}

	negarSolicitacao(id: number): Observable<any> {

		const query = {
			'request.iDSCO0002': id,
			'request.liberado': false
		};

		return this.http.post(this.baseUrl + 'liberar/0001', {}, query);
	}


}
