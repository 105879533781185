import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { Ctr0001 } from '../../../views/pages/01-cadastro/ctr0001/models/ctr0001.model';
import { environment } from '../../../../environments/environment';

// Providers
import { HttpService } from '../../../core/services/http.service';

// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
	providedIn: 'root'
})
export class Ctr0001Service implements IBaseService {

	readonly baseUrl: string = environment.cadastroApiUrl + '/api/ctr0001/';

	constructor(private http: HttpService) { }

	listar(): string {

		return this.baseUrl + '0002';
	}

	cadastrar(model: Ctr0001): Observable<any> {

		return this.http.post(this.baseUrl, model);
	}

	editar(model: Ctr0001): Observable<any> {

		const query: any = {
			id: model.IDCTR0001
		};

		return this.http.put(this.baseUrl, model, query);
	}

	excluir(model: Ctr0001): Observable<any> {

		return this.http.delete(this.baseUrl + model.IDCTR0001);
	}

	visualizar(id: number): Observable<any> {

		return this.http.get(this.baseUrl + id);
	}

	comboBox0003(): string {

		return this.baseUrl + '0003';
	}
}
