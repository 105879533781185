import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { environment } from '../../../../environments/environment';
import { SolicitacaoCompraProduto } from '../../../views/pages/09-suprimentos/solicitacao-compra/models/solicitacao-compra-produto.model';

// Providers
import { HttpService } from '../../../core/services/http.service';

// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
	providedIn: 'root'
})
export class SCO0002Service implements IBaseService {

	readonly baseUrl: string = environment.suprimentosApiUrl + '/api/sco0002/';

	constructor(private http: HttpService) { }

	listar(): string {

		return this.baseUrl + '0002';
	}

	listarByIDSCO0001(): string {

		return this.baseUrl + '0001';
	}

	cadastrar(model: SolicitacaoCompraProduto): Observable<any> {

		return this.http.post(this.baseUrl, model);
	}

	editar(model: SolicitacaoCompraProduto): Observable<any> {

		const query = {
			id: model.IDSCO0002
		};

		return this.http.put(this.baseUrl, model, query);
	}

	excluir(model: SolicitacaoCompraProduto): Observable<any> {

		return this.http.delete(this.baseUrl + model.IDSCO0002);
	}

	visualizar(id: number): Observable<any> {

		return this.http.get(this.baseUrl + id);
	}


}
