import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { HistoricoFiscalModel } from '../../../views/pages/01-cadastro/finalidade/models/historico-fiscal.model';
import { HistoricoPadraoFiscalModel } from '../../../views/pages/07-contabilidade/historico-padrao-fiscal/models/historico-padrao-fiscal.model';
import { environment } from '../../../../environments/environment';

// Providers
import { TokenStorage } from '../../../core/services/token-storage.service';
import { HttpService } from '../../../core/services/http.service';

// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
	providedIn: 'root'
})
export class HistoricoPadraoFiscalService implements IBaseService {

	readonly baseUrl: string = environment.cadastroApiUrl + '/api/historico-padrao-fiscal/';

	constructor(private http: HttpService,
		private tokenStorage: TokenStorage) {
	}

	listar(): string {

		return this.baseUrl + '0003';
	}

	cadastrar(model: HistoricoFiscalModel): Observable<any> {

		return this.http.post(this.baseUrl, model);
	}

	editar(model: HistoricoFiscalModel): Observable<any> {

		const query: any = {
			id: model.IDHistoricoPadraoFiscal
		};

		return this.http.put(this.baseUrl, model, query);
	}

	excluir(model: HistoricoFiscalModel | HistoricoPadraoFiscalModel): Observable<any> {

		return this.http.delete(this.baseUrl + model.IDHistoricoPadraoFiscal);
	}

	visualizar(id: number): Observable<any> {

		return this.http.get(this.baseUrl + id);
	}

	comboBox0002(filter: any): Observable<any> {

		const query: any = {
			'filtros.iDEmpresa': this.tokenStorage.getUnidadeUser().IDEmpresa,
			...filter
		};

		return this.http.get(this.baseUrl + '0002', query);
	}

	comboBox0002Url(): string {

		return this.baseUrl + '0002';
	}
}
