import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { environment } from '../../../../environments/environment';
import { ApuracaoICMSSTModel } from '../../../views/pages/07-contabilidade/apuracao-icmsst/models/apuracao-icmsst.model';

// Providers
import { HttpService } from '../../../core/services/http.service';

// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
	providedIn: 'root'
})
export class ApuracaoICMSSTService implements IBaseService {

	readonly baseUrl: string = environment.contabilidadeApiUrl + '/api/apuracao-icmsst/';

	constructor(private http: HttpService) {
	}

	cadastrar(model: ApuracaoICMSSTModel): Observable<ApuracaoICMSSTModel> {

		return this.http.post(this.baseUrl, model);
	}

	editar(model: ApuracaoICMSSTModel): Observable<ApuracaoICMSSTModel> {

		const query = {
			id: model.IDApuracaoICMSST
		};

		return this.http.put(this.baseUrl, model, query);
	}

	excluir(model: any): Observable<any> {

		return this.http.delete(this.baseUrl + model.IDApuracaoICMSST);
	}

	visualizar(id: number): Observable<ApuracaoICMSSTModel> {

		return this.http.get(this.baseUrl + id);
	}

	apurarSaldos(id: number): Observable<any> {

		const query: any = {
			IDApuracaoICMSST: id
		}
		return this.http.post(this.baseUrl + 'apurar', query);
	}

	listar(): string {
		
		return this.baseUrl + '0001'
	}
	comboBox0002(): string {

		return this.baseUrl + '0002';
	}
}
