import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { environment } from '../../../../environments/environment';
import { MotoristaModel } from '../../../views/pages/01-cadastro/motorista/models/motorista.model';

// Providers
import { TokenStorage } from '../../../core/services/token-storage.service';
import { HttpService } from '../../../core/services/http.service';

// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
	providedIn: 'root'
})
export class MotoristaService implements IBaseService {

	readonly baseUrl: string = environment.cadastroApiUrl + '/api/motorista/';

	constructor(private http: HttpService,
		private tokenStorage: TokenStorage) {
	}

	listar(): string {

		return this.baseUrl + '0001';
	}

	cadastrar(model: MotoristaModel): Observable<any> {

		return this.http.post(this.baseUrl, model);
	}

	editar(model: MotoristaModel): Observable<any> {
		const query: any = {
			id: model.IDMotorista
		};
		return this.http.put(this.baseUrl, model, query);
	}

	excluir(model: MotoristaModel): Observable<any> {

		return this.http.delete(this.baseUrl + model.IDMotorista);
	}

	visualizar(id: number): Observable<any> {

		return this.http.get(this.baseUrl + id);
	}

	/**
	 *
	 * @tutorial: Rota 0002. O campo 'filtros.iDEmpresa' é obrigatório
	 */
	getCombo(filter: any): Observable<any> {

		const query: any = {
			'filtros.iDEmpresa': this.tokenStorage.getUnidadeUser().IDEmpresa,
			...filter
		};

		return this.http.get(this.baseUrl + '0002', query);
	}
	
	comboBox0002(): string {

		return this.baseUrl + '0002';
	}
}
