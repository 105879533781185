import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { environment } from '../../../../environments/environment';
import { EmpresaConfiguracaoFiscalModel } from '../../../views/pages/01-cadastro/empresa-configuracao-fiscal/models/empresa-configuracao-fiscal.model';

// Providers
import { HttpService } from '../../../core/services/http.service';
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
	providedIn: 'root'
})
export class EmpresaConfiguracaoFiscalService implements IBaseService {

	readonly baseUrl: string = environment.cadastroApiUrl + '/api/empresa-configuracao-fiscal/';

	constructor(private http: HttpService) { }

	listar(): string {
		throw new Error('Method not implemented.');
	}

	cadastrar(model: EmpresaConfiguracaoFiscalModel): Observable<any> {
		return this.http.post(this.baseUrl, model);
	}

	editar(model: any): Observable<any> {
		throw new Error('Method not implemented.');
	}

	atualizar(model: EmpresaConfiguracaoFiscalModel): Observable<any> {
		return this.http.put(this.baseUrl, model);
	}

	excluir(model: EmpresaConfiguracaoFiscalModel): Observable<any> {
		return this.http.delete(this.baseUrl + model.IDEmpresaConfiguracaoFiscal);
	}

	visualizar(filter: any): Observable<any> {
		const query: any = {
			...filter
		};

		return this.http.get(this.baseUrl + '0001', query);
	}

	listar0001(filter: any): Observable<any> {
		const query: any = {
			...filter
		};

		return this.http.get(this.baseUrl + '0001', query);
	}
}
