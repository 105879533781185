import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// Models
import { environment } from '../../../../environments/environment';
// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';
// Providers
import { HttpService } from '../../../core/services/http.service';
import { FormatadorGerencialModel } from '../../../views/pages/07-contabilidade/formatador-gerencial/models/formatador-gerencial.model';




@Injectable({
	providedIn: 'root'
})
export class FormatadorGerencialService implements IBaseService {

	readonly baseUrl: string = environment.cadastroApiUrl + '/api/formatador-gerencial/';

	constructor(private http: HttpService) { }

	listar(): string {

		return this.baseUrl + '0001';
	}

	cadastrar(model: FormatadorGerencialModel): Observable<any> {

		return this.http.post(this.baseUrl, model);
	}

	editar(model: FormatadorGerencialModel): Observable<any> {
		const query: any = {
			id: model.IDFormatadorGerencial
		};
		return this.http.put(this.baseUrl, model, query);
	}

	excluir(model: FormatadorGerencialModel): Observable<any> {

		return this.http.delete(this.baseUrl + model.IDFormatadorGerencial);
	}

	excluir0001(arrayIDFormatadorGerencial: Array<number>): Observable<any> {

		const query = {
			'request.arrayIDFormatadorGerencial': arrayIDFormatadorGerencial
		};

		return this.http.delete(this.baseUrl + '/0001', query);
	}

	visualizar(id: number): Observable<any> {

		return this.http.get(this.baseUrl + id);
	}

	comboBox0002(): string {

		return this.baseUrl + '0002';
	}
}
