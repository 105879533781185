import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { environment } from '../../../../environments/environment';
import { VeiculoModel } from '../../../views/pages/09-suprimentos/veiculo/models/veiculo.model';

// Providers
import { HttpService } from '../../../core/services/http.service';

// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
	providedIn: 'root'
})
export class VeiculoService implements IBaseService {

	readonly baseUrl: string = environment.suprimentosApiUrl + '/api/veiculo/';

	constructor(private http: HttpService) { }

	listar(): string {

		return this.baseUrl + '0001';
	}

	cadastrar(model: VeiculoModel): Observable<any> {

		return this.http.post(this.baseUrl, model);
	}

	editar(model: VeiculoModel): Observable<any> {

		return this.http.put(this.baseUrl, model, {
			id: model.IDVeiculo
		});
	}

	excluir(model: VeiculoModel): Observable<any> {

		return this.http.delete(this.baseUrl + model.IDVeiculo);
	}

	visualizar(id: number): Observable<any> {

		return this.http.get(this.baseUrl + id);
	}

	comboBox0002(): string {

		return this.baseUrl + '0002';
	}

	gerarArquivo(body: { IDVeiculo: number }): Observable<any> {

		return this.http.post(this.baseUrl + '0001', body);
	}
}
