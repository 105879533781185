import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { environment } from '../../../../environments/environment';

// Providers
import { TokenStorage } from '../../../core/services/token-storage.service';
import { HttpService } from '../../../core/services/http.service';

// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
	providedIn: 'root'
})
export class ClassificacaoABCService implements IBaseService {

	readonly baseUrl: string = environment.cadastroApiUrl + '/api/classificacao-abc/';
	readonly baseUrlSuprimentos: string = environment.suprimentosApiUrl + '/api/classificacao-abc/';

	constructor(private http: HttpService,
		private tokenStorage: TokenStorage) {
	}

	listar(): string {
		throw new Error('Method not implemented.');
	}

	listar0001(query): Observable<any> {

		return this.http.get(this.baseUrlSuprimentos + '0001', query);
	}

	cadastrar(model: any): Observable<any> {
		throw new Error('Method not implemented.');
	}

	editar(model: any): Observable<any> {
		throw new Error('Method not implemented.');
	}

	excluir(model: any): Observable<any> {
		throw new Error('Method not implemented.');
	}

	visualizar(id: number): Observable<any> {
		throw new Error('Method not implemented.');
	}

	comboBox0002(filter: any): Observable<any> {

		const query: any = {
			'filtros.iDEmpresa': this.tokenStorage.getUnidadeUser().IDEmpresa,
			...filter
		};

		return this.http.get(this.baseUrl + '0002', query);
	}

	comboBox0002Url(): string {

		return this.baseUrl + '0002';
	}
}
