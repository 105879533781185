import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { environment } from '../../../../environments/environment';

// Providers
import { HttpService } from '../../../core/services/http.service';

// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
	providedIn: 'root'
})
export class Fun0001Service implements IBaseService {

	readonly baseUrl: string = environment.vixSysCadastroApiUrl + '/api/fun0001/';

	constructor(private http: HttpService) { }

	listar(): string {
		throw new Error('Method not implemented.');
	}

	cadastrar(model: any): Observable<any> {
		throw new Error('Method not implemented.');
	}

	editar(model: any): Observable<any> {
		throw new Error('Method not implemented.');
	}

	excluir(model: any): Observable<any> {
		throw new Error('Method not implemented.');
	}

	visualizar(id: number): Observable<any> {

		return this.http.get(this.baseUrl + '0003/' + id);
	}

	comboBox0004(filter?: any): Observable<any> {

		const query = {
			...filter
		};

		return this.http.get(this.baseUrl + '0004', query);
	}

	comboBox0004Url(): string {

		return this.baseUrl + '0004';
	}

	comboBox0005Url(): string {

		return this.baseUrl + '0005';
	}

}
