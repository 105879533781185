import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { environment } from '../../../../environments/environment';

// Providers
import { HttpService } from '../../../core/services/http.service';

// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
  providedIn: 'root'
})
export class DRR0001Service implements IBaseService {

  readonly baseUrl: string = environment.vixSysCadastroApiUrl + '/api/drr0001/';

  constructor(private http: HttpService) {
  }

  listar(): string {
    throw new Error('Method not implemented.');
  }

  cadastrar(model: any): Observable<any> {
    throw new Error('Method not implemented.');
  }

  editar(model: any): Observable<any> {
    throw new Error('Method not implemented.');
  }

  excluir(model: any): Observable<any> {
    throw new Error('Method not implemented.');
  }

  visualizar(id: number): Observable<any> {
    throw new Error('Method not implemented.');
  }

  comboBox0001Url(): string {

    return this.baseUrl + '0001';
  }
}
