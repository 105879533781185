import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { environment } from '../../../../environments/environment';
import { ApuracaoIPIModel } from '../../../views/pages/07-contabilidade/apuracao-ipi/models/apuracao-ipi.model';

// Providers
import { HttpService } from '../../../core/services/http.service';

// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
	providedIn: 'root'
})
export class ApuracaoIPIService implements IBaseService {

	readonly baseUrl: string = environment.contabilidadeApiUrl + '/api/apuracao-ipi/';

	constructor(private http: HttpService) {
	}

	listar(): string {
		
		return this.baseUrl + '0001'
	}

	cadastrar(model: ApuracaoIPIModel): Observable<ApuracaoIPIModel> {

		return this.http.post(this.baseUrl, model);
	}

	editar(model: ApuracaoIPIModel): Observable<ApuracaoIPIModel> {

		const query = {
			id: model.IDApuracaoIPI
		};

		return this.http.put(this.baseUrl, model, query);
	}

	excluir(model: any): Observable<any> {

		return this.http.delete(this.baseUrl + model.IDApuracaoIPI);
	}

	visualizar(id: number): Observable<ApuracaoIPIModel> {

		return this.http.get(this.baseUrl + id);
	}

	comboBox0002(): string {

		return this.baseUrl + '0002';
	}
}
