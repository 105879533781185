import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { environment } from '../../../../environments/environment';
import { RelacionamentoComercialModel } from '../../../views/pages/14-comercial/relacionamento-comercial/models/relacionamento-comercial.model';

// Providers
import { HttpService } from '../../../core/services/http.service';
import { TokenStorage } from '../../../core/services/token-storage.service';

// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
	providedIn: 'root'
})
export class RelacionamentoComercialService implements IBaseService {

	readonly baseUrl: string = environment.comercialApiUrl + '/api/relacionamento-comercial/';

	constructor(private http: HttpService,
		private tokenStorage: TokenStorage) { }

	listar() {
		return this.baseUrl + '0006';
	}

	cadastrar(model: RelacionamentoComercialModel) {
		return this.http.post(this.baseUrl, model);
	}

	editar(model: RelacionamentoComercialModel) {
		const query: any = {
			id: model.IDRelacionamentoComercial
		};
		return this.http.put(this.baseUrl, model, query);
	}

	excluir(model: RelacionamentoComercialModel) {
		return this.http.delete(this.baseUrl + model.IDRelacionamentoComercial);
	}

	visualizar(id: number) {
		return this.http.get(this.baseUrl + id);
	}

	getCombo(filter: any): Observable<any> {

		const query: any = {
			'filtros.iDEmpresa': this.tokenStorage.getUnidadeUser().IDEmpresa,
			...filter
		};

		return this.http.get(this.baseUrl + '0002', query);
	}

	comboBox0002(): string {

		return this.baseUrl + '0002';
	}
}
