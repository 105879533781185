import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { environment } from '../../../../environments/environment';
import { ClassificacaoTabelaPrecoModel } from '../../../views/pages/14-comercial/classificacao-tabela-preco/models/classificacao-tabela-preco.model';

// Providers
import { HttpService } from '../../../core/services/http.service';

// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
	providedIn: 'root'
})
export class ClassificacaoTabelaPrecoService implements IBaseService {
	readonly baseUrl: string = environment.comercialApiUrl + '/api/classifica-tabela-preco/';

	constructor(private http: HttpService) { }

	listar(): string {
		return this.baseUrl + '0005';
	}

	cadastrar(model: ClassificacaoTabelaPrecoModel) {
		return this.http.post(this.baseUrl, model);
	}

	editar(model: ClassificacaoTabelaPrecoModel) {
		const query: any = {
			id: model.IDClassificaTabelaPreco
		};
		return this.http.put(this.baseUrl, model, query);
	}

	excluir(model: ClassificacaoTabelaPrecoModel): Observable<any> {
		return this.http.delete(this.baseUrl + model.IDClassificaTabelaPreco);
	}

	visualizar(id: number) {
		return this.http.get(this.baseUrl + id);
	}

	comboBox0002(): string {
		return this.baseUrl + '0002';
	}

}
