import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { environment } from '../../../../environments/environment';
import { GrupoAcessoModel } from '../../../views/pages/01-cadastro/grupo-acesso/models/grupo-acesso.model';

// Providers
import { HttpService } from '../../../core/services/http.service';

// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
	providedIn: 'root'
})
export class GRU0001Service implements IBaseService {
	readonly baseUrl: string = environment.conexaoApiUrl + '/api/gru0001/';

	constructor(private http: HttpService) {
	}

	listar(): string {
		return this.baseUrl + '0008';
	}

	cadastrar(model: GrupoAcessoModel): Observable<any> {
		return this.http.post(this.baseUrl, model);
	}

	cadastrar0002(model: any): Observable<any> {
		return this.http.post(this.baseUrl + '0002', model);
	}

	editar(model: GrupoAcessoModel): Observable<any> {
		return this.http.put(this.baseUrl, model);
	}

	excluir(id: number): Observable<any> {
		return this.http.delete(this.baseUrl + id);
	}

	visualizar(id: number): Observable<any> {
		return this.http.get(this.baseUrl + '/' + id);
	}

	comboBox0003(filter: any): Observable<any> {
		const query: any = {
			...filter
		};

		return this.http.get(this.baseUrl + '0003', query);
	}

	comboBox0003Url(): string {
		return this.baseUrl + '0003';
	}

	copiar(body: { ArrayIDGRU0001: number[] }): Observable<{
		Sucesso: true,
		Mensagem: string,
		ByteArray: string,
		NomeArquivo: string
	}> {
		return this.http.post(this.baseUrl + 'copiar/0001', body);
	}
}
