import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { environment } from '../../../../environments/environment';

import { ContaContabilReferencialModel } from '../../../views/pages/07-contabilidade/conta-contabil-referencial/models/conta-contabil-referencial.model';

// Providers
import { TokenStorage } from '../../../core/services/token-storage.service';
import { HttpService } from '../../../core/services/http.service';

// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
  providedIn: 'root'
})
export class ContaContabilReferencialService implements IBaseService {
  readonly baseUrl: string = environment.contabilidadeApiUrl + '/api/conta-contabil-referencial/';

  constructor(private http: HttpService,
    private tokenStorage: TokenStorage) {
  }

  listar(): string {
    return this.baseUrl + '0002';
  }

  cadastrar(model: ContaContabilReferencialModel): Observable<any> {
    return this.http.post(this.baseUrl, model);
  }

  editar(model: ContaContabilReferencialModel): Observable<any> {
    return this.http.put(this.baseUrl, model);
  }

  excluir(model: ContaContabilReferencialModel): Observable<any> {
    return this.http.delete(this.baseUrl + model.IDContaReferencial);
  }

  visualizar(id: number): Observable<any> {
    return this.http.get(this.baseUrl + id);
  }

  comboBox0001(filter: any): Observable<any> {
    const query: any = {
      'filtros.iDEmpresa': this.tokenStorage.getUnidadeUser().IDEmpresa,
      ...filter
    };

    return this.http.get(this.baseUrl + '0001', query);
  }

  comboBox0001Url(): string {
    return this.baseUrl + '0001';
  }
}
