import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { environment } from '../../../../environments/environment';
import { AtividadeContribuicaoPrevidenciariaModel } from '../../../views/pages/07-contabilidade/atividade-contribuicao-previdenciaria/models/atividade-contribuicao-previdenciaria.model';

// Providers
import { HttpService } from '../../../core/services/http.service';

// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
	providedIn: 'root'
})
export class AtividadeContribuicaoPrevidenciariaService implements IBaseService {

	readonly baseUrl: string = environment.cadastroApiUrl + '/api/atividade-contribuicao-previdenciaria/';

	constructor(private http: HttpService) { }

	listar(): string {

		return this.baseUrl + '0003';
	}

	cadastrar(model: AtividadeContribuicaoPrevidenciariaModel): Observable<any> {

		return this.http.post(this.baseUrl, model);
	}

	editar(model: AtividadeContribuicaoPrevidenciariaModel): Observable<any> {
		return this.http.put(this.baseUrl, model, {
			id: model.IDAtividadeContribuicaoPrevidenciaria
		});
	}

	excluir(model: AtividadeContribuicaoPrevidenciariaModel): Observable<any> {

		return this.http.delete(this.baseUrl + model.IDAtividadeContribuicaoPrevidenciaria);
	}

	visualizar(id: number): Observable<any> {

		return this.http.get(this.baseUrl + id);
	}

	comboBox0002Url(): string {

		return this.baseUrl + '0002';
	}
}
