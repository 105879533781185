import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { environment } from '../../../../environments/environment';

// Providers
import { HttpService } from '../../../core/services/http.service';

// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
	providedIn: 'root'
})
export class CaixaService implements IBaseService {

	readonly baseUrl: string = environment.cadastroApiUrl + '/api/caixa/';

	constructor(private http: HttpService) { }

	listar(): string {
		return this.baseUrl + '0002';
	}

	listar0003(): string {
		return this.baseUrl + '0003';
	}

	comboBox0004Url(): string {

		return this.baseUrl + '0004';
	}

	cadastrar(model: object): Observable<any> {
		throw new Error('Method not implemented.');
	}

	editar(model: object): Observable<any> {
		throw new Error('Method not implemented.');
	}

	excluir(model: any): Observable<any> {
		throw new Error('Method not implemented.');
	}

	visualizar(id: number): Observable<any> {
		return this.http.get(this.baseUrl + id);
	}

	finalizar(ids: number[]): Observable<any> {
		return this.http.post(this.baseUrl + 'finalizar/0001', {
			ArrayIDCaixa: ids
		});
	}

	estornar(ids: number[]): Observable<any> {
		return this.http.post(this.baseUrl + 'estornar/0001', {
			ArrayIDCaixa: ids
		});
	}
}
