import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { environment } from '../../../../environments/environment';
import { ClassificacaoComercialModel } from '../../../views/pages/14-comercial/classificacao-comercial/models/classificacao-comercial.model';

// Providers
import { TokenStorage } from '../../../core/services/token-storage.service';
import { HttpService } from '../../../core/services/http.service';

// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
	providedIn: 'root'
})
export class ClassificacaoComercialService implements IBaseService {

	readonly baseUrl: string = environment.comercialApiUrl + '/api/classificacao-comercial/';

	constructor(private http: HttpService,
		private tokenStorage: TokenStorage) { }

	listar(): string {
		return this.baseUrl + '0006';
	}

	cadastrar(model: ClassificacaoComercialModel): Observable<any> {
		return this.http.post(this.baseUrl, model);
	}

	editar(model: ClassificacaoComercialModel): Observable<any> {
		const query: any = {
			id: model.IDClassificacaoComercial
		};
		return this.http.put(this.baseUrl, model, query);
	}

	excluir(model: ClassificacaoComercialModel): Observable<any> {
		return this.http.delete(this.baseUrl + model.IDClassificacaoComercial);
	}

	visualizar(id: number): Observable<any> {
		return this.http.get(this.baseUrl + id);
	}

	comboBox0002(): string {

		return this.baseUrl + '0002';
	}
}
