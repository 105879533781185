import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { environment } from '../../../../environments/environment';
import { CategoriaProdutoServicoModel } from '../../../views/pages/09-suprimentos/categoria-produtos-servicos/model/categoria-produto-servico.model';

// Providers
import { TokenStorage } from '../../../core/services/token-storage.service';
import { HttpService } from '../../../core/services/http.service';

// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
	providedIn: 'root'
})
export class Cat0001Service implements IBaseService {

	readonly baseUrl: string = environment.suprimentosApiUrl + '/api/cat0001/';

	constructor(private http: HttpService,
		private tokenStorage: TokenStorage) { }

	listar(): string {
		return this.baseUrl + '0005';
	}

	cadastrar(model: CategoriaProdutoServicoModel): Observable<any> {
		return this.http.post(this.baseUrl, model);
	}

	editar(model: CategoriaProdutoServicoModel): Observable<any> {
		const query: any = {
			id: model.IDCAT0001
		};
		return this.http.put(this.baseUrl, model, query);
	}

	excluir(model: CategoriaProdutoServicoModel): Observable<any> {
		return this.http.delete(this.baseUrl + model.IDCAT0001);
	}

	visualizar(id: number): Observable<any> {
		return this.http.get(this.baseUrl + id);
	}

	comboBox0002(): string {

		return this.baseUrl + '0002';
	}

}
