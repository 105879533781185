import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { environment } from '../../../../environments/environment';
import { DiaVisitaModel } from '../../../views/pages/14-comercial/dia-visita/models/dia-visita.model';

// Providers
import { HttpService } from '../../../core/services/http.service';
import { TokenStorage } from '../../../core/services/token-storage.service';

// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
	providedIn: 'root'
})
export class DiaVisitaService implements IBaseService {
	readonly baseUrl: string = environment.comercialApiUrl + '/api/dia-visita/';

	constructor(private http: HttpService,
		private tokenStorage: TokenStorage) {
	}

	listar(): string {
		return this.baseUrl + '0006';
	}

	cadastrar(model: DiaVisitaModel): Observable<any> {
		return this.http.post(this.baseUrl, model);
	}

	editar(model: DiaVisitaModel): Observable<any> {

		return this.http.put(this.baseUrl, model, {
			id: model.IDDiaVisita
		});
	}

	excluir(model: DiaVisitaModel): Observable<any> {
		return this.http.delete(this.baseUrl + model.IDDiaVisita);
	}

	visualizar(id: any): Observable<any> {
		return this.http.get(this.baseUrl + id);
	}

	comboBox0002(filter: any): Observable<any> {

		const query: any = {
			'filtros.iDEmpresa': this.tokenStorage.getUnidadeUser().IDEmpresa,
			...filter
		};

		return this.http.get(this.baseUrl + '0002', query);
	}

	comboBox0002Url(): string {

		return this.baseUrl + '0002';
	}

}
