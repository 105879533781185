export class PessoaColaboradorModel {

	IDPessoaColaborador: number = null;
	Nome: string = null;
	CPF: string = null;
	RG: string = null;
	CNH: string = null;
	CategoriaCNH: string = null;
	DataNascimento: any = null;
	Matricula: string = null;
	IDSexo: number = null;
	IDEstadoCivil: number = null;
	CodigoIBGEPais: number = null;
	IDPais: number = null;
	CodigoIBGEEstado: number = null;
	IDEstado: number = null;
	CodigoIBGECidade: number = null;
	CEP: string = null;
	Bairro: string = null;
	Logradouro: string = null;
	Numero: string = null;
	Complemento: string = null;
	Terceiro: boolean = false;
	IDUsuario: number = null;
	Ativo: boolean = true;
	IDPessoa: number = null;
	Telefone: string = null;
	Ramal: number = null;
	Celular: string = null;
	Email: string = null;
	Agencia: string = null;
	Conta: string = null;
	IDBanco: number = null;
	SalarioBase: number = null;

	constructor() { }
}
