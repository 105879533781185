import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { environment } from '../../../../environments/environment';
import { CIM0001Model } from '../../../views/pages/06-patrimonio/cim0001/models/cim0001.model';

// Providers
import { HttpService } from '../../../core/services/http.service';

// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
	providedIn: 'root'
})
export class CIM0001Service implements IBaseService {

	readonly baseUrl: string = environment.patrimonioApiUrl + '/api/cim0001/';

	constructor(private http: HttpService) {
	}

	listar(): string {

		return this.baseUrl + '0001';
	}

	cadastrar(model: CIM0001Model): Observable<any> {

		return this.http.post(this.baseUrl, model);
	}

	editar(model: CIM0001Model): Observable<any> {
		const query: any = {
			id: model.IDCIM0001
		};
		return this.http.put(this.baseUrl, model, query);
	}

	excluir(model: CIM0001Model): Observable<any> {

		return this.http.delete(this.baseUrl + model.IDCIM0001);
	}

	visualizar(id: number): Observable<any> {

		return this.http.get(this.baseUrl + id);
	}

	comboBox0002(): string {

		return this.baseUrl + '0002';
	}
}
