import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { environment } from '../../../../environments/environment';

// Providers
import { TokenStorage } from '../../../core/services/token-storage.service';
import { HttpService } from '../../../core/services/http.service';

// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';
import { RamoAtividadeModel } from '../../../views/pages/14-comercial/ramo-atividade/models/ramo-atividade.model';

@Injectable({
	providedIn: 'root'
})
export class RamoAtividadeService implements IBaseService {

	readonly baseUrl: string = environment.comercialApiUrl + '/api/ramo-atividade/';

	constructor(private http: HttpService,
		private tokenStorage: TokenStorage) { }

	listar() {
		return this.baseUrl + '0006';
	}

	cadastrar(model: RamoAtividadeModel): Observable<RamoAtividadeModel> {
		return this.http.post(this.baseUrl, model);
	}

	editar(model: RamoAtividadeModel): Observable<RamoAtividadeModel> {

		return this.http.put(this.baseUrl, model, {
			id: model.IDRamoAtividade
		});
	}

	excluir(model: RamoAtividadeModel) {
		return this.http.delete(this.baseUrl + model.IDRamoAtividade);
	}

	visualizar(id: number) {
		return this.http.get(this.baseUrl + id);
	}

	getCombo(filter: any): Observable<any> {

		const query: any = {
			'filtros.iDEmpresa': this.tokenStorage.getUnidadeUser().IDEmpresa,
			...filter
		};

		return this.http.get(this.baseUrl + '0002', query);
	}

	comboBox0002(): string {

		return this.baseUrl + '0002';
	}
}
