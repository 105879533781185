import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { environment } from '../../../../environments/environment';
import { ApuracaoICMSModel } from '../../../views/pages/07-contabilidade/apuracao-icms/models/apuracao-icms.model';

// Providers
import { HttpService } from '../../../core/services/http.service';

// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
	providedIn: 'root'
})
export class ApuracaoICMSService implements IBaseService {

	readonly baseUrl: string = environment.contabilidadeApiUrl + '/api/apuracao-icms/';

	constructor(private http: HttpService) {
	}

	cadastrar(model: ApuracaoICMSModel): Observable<ApuracaoICMSModel> {

		return this.http.post(this.baseUrl, model);
	}

	editar(model: ApuracaoICMSModel): Observable<ApuracaoICMSModel> {

		const query = {
			id: model.IDApuracaoICMS
		};

		return this.http.put(this.baseUrl, model, query);
	}

	excluir(model: any): Observable<any> {

		return this.http.delete(this.baseUrl + model.IDApuracaoICMS);
	}

	visualizar(id: number): Observable<ApuracaoICMSModel> {

		return this.http.get(this.baseUrl + id);
	}

	apurarSaldos(id: number): Observable<any> {

		const query: any = {
			IDApuracaoICMS: id
		}
		return this.http.post(this.baseUrl + 'apurar', query);
	}

	listar(): string {
		
		return this.baseUrl + '0001'
	}
	comboBox0002(): string {

		return this.baseUrl + '0002';
	}
}
